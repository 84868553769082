<!--
 * @Author: 刘格优
 * @Date: 2020-06-16 17:34:45
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-06-29 20:06:48
--> 
<template>
  <div>
    <p class="title">{{title}}</p>
    <div class="bottombox">
      <ul v-if="one.length > 0"
          class="contentbox">
        <div class="typebg">
          <span>单选题</span>
        </div>
        <li v-for="(item,index) in one"
            :key="index"
            class="ali">
          <div class="toptitle">
            <span>{{index+1}}、</span>
            <span>{{item.question}}</span>
            <span class="score">{{`（${item.score}分）`}}</span>
          </div>
          <div class="question">
            <md-radio-list v-model="item.myanswer"
                           :options="item.questiondata"
                           icon=""
                           icon-inverse="check"
                           icon-position="right">
              <template slot-scope="{ option, index, selected }">
                <div class="custom-brief">
                  <span class="left"
                        :class="selected ? 'oranger':''"></span>
                  <span class="words"> {{option.xuhao}}、{{ option.content }}</span>
                  <img src="@/assets/xxb/checked.png"
                       alt=""
                       class="right"
                       v-if="selected">
                </div>
              </template>
            </md-radio-list>
          </div>

        </li>
      </ul>
      <ul v-if="many.length > 0"
          class="contentbox">
        <div class="typebg">
          <span>多选题</span>
        </div>
        <li v-for="(item,index) in many"
            :key="index"
            class="ali">
          <div class="toptitle">
            <span>{{index+1}}、</span>
            <span>{{item.question}}</span>
            <span class="score">{{`（${item.score}分）`}}</span>
          </div>
          <div class="question">
            <md-check-list ref="checkList"
                           v-model="item.myanswer"
                           :options="item.questiondata"
                           icon=""
                           icon-inverse="check"
                           icon-position="right">
              <template slot-scope="{ option, index, selected }">
                <div class="custom-brief">
                  <span class="left"
                        :class="selected ? 'oranger':''"></span>
                  <span class="words"> {{option.xuhao}}、{{ option.content }}</span>
                  <img src="@/assets/xxb/checked.png"
                       alt=""
                       class="right"
                       v-if="selected">
                </div>
              </template>
            </md-check-list>
          </div>
        </li>

      </ul>
      <ul v-if="judge.length > 0"
          class="contentbox">
        <div class="typebg">
          <span>判断题</span>
        </div>
        <li v-for="(item,index) in judge"
            :key="index"
            class="ali">
          <div class="toptitle">
            <span>{{index+1}}、</span>
            <span>{{item.question}}</span>
            <span class="score">{{`（${item.score}分）`}}</span>
          </div>
          <div class="question">
            <md-radio-list v-model="item.myanswer"
                           :options="reasons"
                           icon=""
                           icon-inverse="check"
                           icon-position="right">
              <template slot-scope="{ option, index, selected }">
                <div class="custom-brief">
                  <span class="left"
                        :class="selected ? 'oranger':''"></span>
                  <span class="words"> {{option.xuhao}}、{{ option.text }}</span>
                  <img src="@/assets/xxb/checked.png"
                       alt=""
                       class="right"
                       v-if="selected">
                </div>
              </template>
            </md-radio-list>
          </div>
        </li>
      </ul>
      <md-button class="btn"
                 @click="submit"
                 :inactive="isloading">{{isloading?'提交中':'提交'}}</md-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'mand-mobile'
import { getresult, testres } from '@/api/xxb/index'
import { RadioList } from 'mand-mobile'
import { cloneDeep } from 'lodash'
export default {
  components: {
    [RadioList.name]: RadioList,
  },
  data () {
    return {
      code: '',
      title: '',
      judge: [],
      many: [],
      one: [],
      reasons: [
        {
          value: 'Y',
          text: '对',
          xuhao: 'A',
        },
        {
          value: 'N',
          text: '错',
          xuhao: 'B',
        },
      ],
      indexarr: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'
      ],
      ksdata: {},
      isloading: false
    };
  },
  created () {
    this.code = this.$route.query.papercode
    this.getdetail()
  },

  methods: {
    getdetail () {
      getresult({ papercode: this.code, newtest: '1' }).then((res) => {
        this.ksdata = res.data.data
        this.title = this.ksdata.title
        document.title = this.ksdata.title
        this.judge = this.ksdata.judge
        this.many = this.ksdata.many
        this.one = this.ksdata.one
        this.judge.forEach((item, index) => {
          this.$set(item, 'myanswer', '')
        })
        this.many.forEach((item, index) => {
          this.randomArr(item.questiondata)
          this.$set(item, 'myanswer', [])
        })
        this.one.forEach((item, index) => {
          this.randomArr(item.questiondata)
          this.$set(item, 'myanswer', '')
        })
      })
    },
    randomArr (arr) {
      let length = arr.length;
      while (length > 1) {
        let index = Math.floor(Math.random() * length--);
        [arr[length], arr[index]] = [arr[index], arr[length]];
      }
      arr.forEach((item, index) => {
        this.$set(arr[index], 'xuhao', this.indexarr[index])
        this.$set(arr[index], 'value', item.itemcode)
      })
      return arr;
    },
    submit () {
      this.isloading = true
      let data = cloneDeep(this.ksdata)
      data.many.forEach((item, index) => {
        let arr = []
        item.questiondata.forEach((citem, cindex) => {
          item.answer.split(',').forEach((ditem, dindex) => {
            if (citem.itemcode == ditem) {
              arr.push(citem.xuhao)
            }
          })
        })
        this.$set(item, 'showanswer', arr)
        item.myanswer = item.myanswer.join(',')
        item.showanswer = item.showanswer.join(',')
      })
      data.one.forEach((item, index) => {
        item.questiondata.forEach((citem, cindex) => {
          if (citem.itemcode == item.answer) {
            this.$set(item, 'showanswer', citem.xuhao)
          }
        })
      });
      data.judge.forEach((item, index) => {
        let answer = item.answer == 'Y' ? 'A' : 'B'
        this.$set(item, 'showanswer', answer)
      });
      testres(data).then((res) => {
        Toast.succeed('提交成功')
        this.$router.push({
          path: `/testrecode?papercode=${this.code}`,
        })
      }).finally(() => {
        this.isloading = false
      })
    }
  }
}

</script>
<style scoped lang="stylus">
.title {
  font-size: 0.45rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(56, 56, 56, 1);
  padding: 0.67rem 0 0 0.85rem;
}

.bottombox {
  padding: 0.4rem 0.4rem;
}

.contentbox {
  box-shadow: 0.1rem 0.1rem 0.5rem #dedede;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
}

.typebg {
  background-image: url('~@/assets/xxb/typebg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 0.17rem 0.5rem;

  span {
    color: #7F5140;
    font-size: 0.43rem;
    font-weight: 400;
  }
}

.ali {
  padding: 0.4rem 0.5rem 0 0.4rem;
  border-bottom: 1px solid #eceaea;

  .toptitle {
    color: #323232;
    font-size: 0.43rem;
    font-weight: bold;
    line-height: 0.6rem;

    .score {
      color: #00a0f3;
      font-weight: 100;
      font-size: 0.36rem;
    }
  }
}

.question {
  display: flex;

  /deep/ .md-radio-list {
    width: 100%;
    padding: 0.2rem 0;
  }

  /deep/ .md-check-group {
    width: 100%;
    padding: 0.2rem 0rem;
  }

  /deep/ .md-icon {
    font-size: 0.5rem !important;
  }

  /deep/ .is-checked {
    color: #fb6822;
  }

  .leftbox {
    .left {
      height: 1rem;
      line-height: 1rem;
      margin: 0.12rem 0 0.12rem 0.4rem;
    }
  }

  .rightbox {
    .right {
      height: 1rem;
      line-height: 1rem;
    }
  }
}

.btn {
  height: 1.3rem;
  line-height: 1.3rem;
  background-color: #fb6822;
  width: 84%;
  margin: 10% auto;
  text-align: center;
  border-radius: 2rem;
  color: #ffffff;
}

.custom-brief {
  display: flex;
  align-items: center;

  .left {
    height: 1rem;
    width: 0.13rem;
    display: inline-block;
    position: relative;
    right: 0.4rem;
  }

  .oranger {
    background-color: #FB6821;
  }

  .words {
    font-size: 0.35rem;
    padding-left: 0.4rem;
  }

  .right {
    width: 0.5rem;
    position: absolute;
    height: auto;
    right: 0;
  }
}
</style>